import React from 'react'
import styled from '@emotion/styled'

const Hidden = styled.div`
  position: relative;
  z-index: 1;
  font-size: ${props => props.theme.fontSizes[4]}px;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[3]}px;
  }
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    font-size: ${props => props.theme.fontSizes[3]}px;
  }
  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    font-size: ${props => props.theme.fontSizes[2]}px;
  }
  .shown,
  .hidden {
    transition: padding 0.1s, height 0.1s, opacity 0.5s;
  }

  .shown {
    padding: 30px 15px 0;
    height: auto;
    opacity: 1;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 30px 30px 0;
    }
  }

  .hidden {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    z-index: 1;
    .shown {
      padding-top: 0;
      opacity: 0;
      height: 0;
      z-index: 1;
    }
    .hidden {
      opacity: 1;
      height: auto;
      z-index: 2;
      pointer-events: auto;
    }
  }
`

const ListeHidden = props => {
  return (
    <Hidden>
      <div className="shown">{props.text}</div>
      <div className="hidden">{props.children}</div>
    </Hidden>
  )
}

export default ListeHidden
