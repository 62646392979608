import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'

const Nav = styled.nav`
  background-color: ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.background};
  flex: 0 0 100%;
  max-width: 100%;
  ul {
    width: 100%;
    max-width: 100%;
    display: inline-block;
    font-size: ${props => props.theme.fontSizes[4]}px;
    line-height: 1.4;
    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      font-size: ${props => props.theme.fontSizes[3]}px;
    }
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      font-size: ${props => props.theme.fontSizes[3]}px;
    }
    @media (max-width: ${props => props.theme.responsive.smallMax}) {
      font-size: ${props => props.theme.fontSizes[2]}px;
    }
  }

  li {
    display: inline-block;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    padding: 10px 15px;
    border-bottom: 2px solid ${props => props.theme.colors.background};
    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 10px 30px;
    }
  }

  a {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    text-decoration: none;
    color: ${props => props.theme.colors.background};
  }
`

const ListeProdukte = props => {
  const data = useStaticQuery(graphql`
    {
      allContentfulProdukte {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
    }
  `)
  return (
    <Nav>
      <ul>
        {data.allContentfulProdukte.edges.map(({ node: produkt }) => (
          <li key={produkt.id}>
            <Link to={`/produkte/${produkt.slug}/`}>{produkt.title}</Link>
          </li>
        ))}
      </ul>
    </Nav>
  )
}

export default ListeProdukte
