import React from 'react'
import { graphql } from 'gatsby'
import ContentProduktLeistung from '../components/ContentProduktLeistung'
import Newsletter from '../components/NewsletterPopup.js'

const ProduktTemplate = ({ data }) => {
  return (
    <>
      <Newsletter />
      <ContentProduktLeistung transfer={data.contentfulProdukte} />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulProdukte(slug: { eq: $slug }) {
      metaDescription {
        internal {
          content
        }
      }
      farbe
      title
      slug
      intBeschreibung {
        json
      }
      logo {
        title
        fixed(width: 150) {
          ...GatsbyContentfulFixed
        }
      }
      ansprechpartnerBild {
        title
        fluid(maxWidth: 398) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ansprechpartnerEmail
      ansprechpartnerName
      ansprechpartnerTelefon
      ansprechpartnerZitat {
        internal {
          content
        }
      }
      backgroundImage {
        title
        fluid(maxWidth: 996) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      download {
        file {
          url
        }
      }
      content {
        json
      }
      kunden
      metaTitle
      contentListe {
        json
      }
    }
  }
`

export default ProduktTemplate
