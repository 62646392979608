/** @jsx jsx */
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'

const ID = styled.div`
  border-radius: 999px;
  height: 120px;
  width: 120px;
  line-height: 120px;
  text-align: center;
  border: 2px solid #000;
  transition: all 0.4s;
  font-size: ${props => props.theme.fontSizes[7]}px;
  opacity: 1 !important;
  &:hover {
    transform: rotate(360deg);
  }
  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    height: 100px;
    width: 100px;
    line-height: 100px;
    font-size: ${props => props.theme.fontSizes[6]}px;
    &:hover {
      transform: rotate(360deg);
    }
  }
`

const Identifier = props => {
  return (
    <ID
      sx={{
        color: `${props.farbe && props.farbe.toLowerCase()}`,
        borderColor: `${props.farbe && props.farbe.toLowerCase()}`,
      }}
    >
      {props.children}
    </ID>
  )
}

export default Identifier
